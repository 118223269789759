gsap.registerPlugin(CSSRulePlugin, Draggable, EasePack, PixiPlugin, EaselPlugin, ScrollToPlugin, CustomEase);
var EMA = EMA || {};


EMA.BUILD = (function() {
    return {
        init: function() {
            // Find all controller in page
            // Define template or content

            $('[data-control]').each(function(index, elem) {
                var data = $(elem).data(),
                    control = data.control.split(',');

                for (var i = 0; i < control.length; i++) {
                    var controller = control[i];
                    if (!EMA[controller]) {
                        return;
                    }

                    if (typeof EMA[controller] === 'function') {
                        var obj = new EMA[controller]();
                        obj.init(elem, data);
                    } else if (typeof EMA[controller] === 'object') {
                        EMA[controller].init(elem, data);
                    }
                }
            });

            // Convert IMG to SVG source code inline
            this.svg('img.svg-convert');

            // Load Lazy Images with animation
            this.lazyLoader();
        },
        templateEngine: function(html, options) {

            // Git : https://github.com/krasimir/absurd/blob/master/lib/processors/html/helpers/TemplateEngine.js

            var re = /<%(.+?)%>/g,
                reExp = /(^( )?(var|if|for|else|switch|case|break|{|}|;))(.*)?/g,
                code = 'with(obj) { var r=[];\n',
                cursor = 0,
                result, match;

            var add = function(line, js) {
                js ? (code += line.match(reExp) ? line + '\n' : 'r.push(' + line + ');\n') : (code += line !== '' ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n' : '');
                return add;
            };

            while (match = re.exec(html)) {
                add(html.slice(cursor, match.index))(match[1], true);
                cursor = match.index + match[0].length;
            }

            add(html.substr(cursor, html.length - cursor));
            code = (code + 'return r.join(""); }').replace(/[\r\t\n]/g, ' ');

            try {
                result = new Function('obj', code).apply(options, [options]);
            } catch (err) {
                console.error("'" + err.message + "'", ' in \n\nCode:\n', code, '\n');
            }

            return result;
        },
        lazyLoader: function() {

            var bLazy = new Blazy({
                offset: -100,
                success: function(element) {
                    setTimeout(function() {
                        // We want to remove the loader gif now.
                        // First we find the parent container
                        // then we remove the "loading" class which holds the loader image
                        var parent = element.parentNode;
                        parent.className = parent.className.replace(/\bloading\b/, '');
                    }, 100);
                }
            });

            return bLazy;

        },
        svg: function(elem) {
            $(elem).each(function() {
                var $img = $(this),
                    imgID = $img.attr('id'),
                    imgClass = $img.attr('class'),
                    imgURL = $img.attr('src'),
                    imgWidth = $img.attr('width'),
                    imgHeight = $img.attr('height');

                $.get(imgURL, function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's ID to the new SVG
                    if (typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if (typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgWidth !== 'undefined') {
                        $svg = $svg.css('width', imgWidth);
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgHeight !== 'undefined') {
                        $svg = $svg.css('height', imgHeight);
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Replace image with new SVG
                    $img.replaceWith($svg);

                }, 'xml');

            });
        },
        viewportOfElement: function(el) {
            var top = el.offsetTop;
            var left = el.offsetLeft;
            var width = el.offsetWidth;
            var height = el.offsetHeight;

            while (el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }

            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        },
        resize: function(offset, element) {
            var scrollTop = Math.round($(window).scrollTop()),
                windowHeight = Math.round($(window).height()),
                windowWidth = Math.round($(window).width()),
                documentHeight = Math.round($(document).height()),
                elementTop = (element) ? Math.round($(element).offset().top) : null,
                elementHeight = (element) ? Math.round($(element).outerHeight(true)) : null,
                elementOffset = (elementTop) ? elementTop - Math.round(offset) : null,
                offsetDocument = (offset) ? documentHeight - Math.round(offset) : documentHeight,
                elementScrollBottom = (element) ? ((elementTop - scrollTop) + elementHeight) : null;

            return {
                scrollTop: scrollTop,
                windowHeight: windowHeight,
                windowWidth: windowWidth,
                documentHeight: documentHeight,
                ifBottom: scrollTop + windowHeight >= offsetDocument,
                elementTop: elementTop,
                elementOffset: elementOffset,
                elementHeight: elementHeight,
                elementScrollBottom: elementScrollBottom,
                elementTouchBottom: elementScrollBottom > windowHeight
            };
        },
        parallax: function(image, speed) {
            if (Foundation.MediaQuery.atLeast('medium')) {
                image.css('transform', 'translate3D(0, ' + (EMA.BUILD.resize().scrollTop * speed) + 'px, 0)');
            }
        }
    };
})();


EMA.NAV = (function() {
    return {
        init: function() {
        // get the value of the bottom of the #main element by adding the offset of that element plus its height, set it as a variable
        var mainbottom = $('#main').offset().top;

        // on scroll,
        var cookiesAnim = false;
        $(window).on('scroll',function(){

                // we round here to reduce a little workload
            stop = Math.round($(window).scrollTop());

            if (stop > mainbottom) {
                if(!cookiesAnim) {
                    cookiesAnim = true;
                    var modal = document.getElementsByClassName("modal-cookies");
                    var delayInMilliseconds = 2000;
                    setTimeout(function() {
                        EMA.COOKIESMODAL.hideCookies(modal);
                    }, delayInMilliseconds);

                }

                $('.navbar').addClass('past-main');
                $('.navbar').addClass('effect-main')
            } else {
                $('.navbar').removeClass('past-main');
        }

        });
        }
    }
})();

EMA.HOME = (function() {
    return {
        init: function() {
            var self = this;
            // Custom Scripts for Pelican Template //
        //console.log("self");
        const instance = new TypeIt('#headerType',  {

            speed: 80,
            waitUntilVisible: true,
            deleteSpeed: 80,
            loopDelay: 1000,
            loop: true
        }).pause(2000).delete(20).pause(400).type('discriminação fonológica')
        .pause(2000).delete(25).pause(300).type('percepção')
        .pause(2000).delete(9).pause(300).type('consciência fonológica')
        .pause(2000).delete(23).pause(300).type('acesso lexical')
        .pause(2000).go();


        


          // Collapse navbar on click

          //  $(document).on('click.nav','.navbar-collapse.in',function(e) {
          //   if( $(e.target).is('a') ) {
          //   $(this).removeClass('in').addClass('collapse');
          //  }
          // });


            /*-----------------------------------
            ----------- Scroll To Top -----------
            ------------------------------------*/

            $(window).on('scroll', function () {
              if ($(this).scrollTop() > 1000) {
                  $('#back-top').fadeIn();
              } else {
                  $('#back-top').fadeOut();
              }
            });
            // scroll body to 0px on click
            $('#back-top').on('click', function () {

              $('body,html').animate({
                  scrollTop: 0
              }, 1500);
              return false;
            });


            /*-------- Owl Carousel ---------- */

              $(".review-cards").owlCarousel({
                slideSpeed: 1500,
                items: 1,
                singleItem: true,
                autoplay:true,
                autoplayTimeout:5000,
                autoplayHoverPause:true,
                pagination: false,
                loop: true
              });

              $('a.js-scroll-trigger[href*="#"]:not([href="#"])').on('click', function () {
                  $window = $(window);
                if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                  var target =this.hash;
                //   target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                  if (target.length) {
                    // $('html, body').animate({
                    //   scrollTop: (target.offset().top - 54)
                    // }, 1000, Power0.easeInOut);
                    
                    var $window = $(window);
                    gsap.to(window, {duration: 2, scrollTo:target, ease: Back.easeInOut});
                    return false;
                  }
                }
              });




            }
        }

})();
EMA.GAMELIST = (function() {
    return {
        init: function() {     
            
        }
    }
})();
EMA.MODAL = (function() {
    
    return {
        init: function(elem) {
            var self = this;
            //self.show(elem);
            $(elem).find(".close-modal").on('click', function(e){
                e.preventDefault();
                self.hide(elem);
            })
            // var date = document.getElementById('birthDate');

            // function checkValue(str, max) {
            //   if (str.charAt(0) !== '0' || str == '00') {
            //     var num = parseInt(str);
            //     if (isNaN(num) || num <= 0 || num > max) num = 1;
            //     str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
            //   };
            //   return str;
            // };

            // date.addEventListener('keydown', function(e) {
            //   this.type = 'text';
            //   var input = this.value;
            //   var key = e.keyCode || e.charCode;

            //   if (key == 8 || key == 46)    // here's where it checks if backspace or delete is being pressed
            //     return false;

            //   if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
            //   var values = input.split('/').map(function(v) {
            //     return v.replace(/\D/g, '')
            //   });
            //   if (values[0]) values[0] = checkValue(values[0], 31);
            //   if (values[1]) values[1] = checkValue(values[1], 12);
            //   var output = values.map(function(v, i) {
            //     return v.length == 2 && i < 2 ? v + '/' : v;
            //   });
            //   this.value = output.join('').substr(0, 10);
            // });


            $('.show-form').on('click',function(e){
                // var label = $(this).data('label');
                // var type = requestTypeForm = $(this).data('type');


                // $('#dinerocuentaForm .products .products-type, .credit-amounts').hide();

                // $('.tab-pane .active').removeClass("active");

                // $('#dinerocuentaForm .products .products-type.'+type+', .credit-amounts.'+type).show();
                // $('#dinerocuentaForm .typeLabel').html(label);

                // if(type == "cuentas") {
                //     $('.fullNameOutput, .form-nav .arrow, .form-progress-status, .progress').hide();
                //     $('.nav-tabs > .nav-item:last-child() > .nav-link').trigger('click');
                // } else {
                //     $('.fullNameOutput, .form-nav .arrow, .form-progress-status, .progress').show();
                //     $('.nav-tabs > .nav-item:first-child() > .nav-link').trigger('click');
                // }
                // console.log("label", label);
                e.preventDefault();
                self.show(elem);
            })



            // dinerocuentaForm = $('#dinerocuentaForm');
            // dinerocuentaForm.on("keyup", function(e) {
            //     var code = e.keyCode || e.which;

            //     if (code === 13) {
            //         if(EMA.MODAL.isCurrentTabValid()){
            //             $('.nav-tabs > .nav-item > .active').parent().next('li').find('a').trigger('click');
            //         }
            //         e.preventDefault();
            //         return false;
            //     }
            // });
            // dinerocuentaForm.validate({
            //   submitHandler: function(form) {
            //     var data = $(form).serialize();
            //     console.log(data);
            //      $.ajax({
            //         type: 'post',
            //         url: '/ema/frontend/engine/engine.php',
            //         data: data,
            //         success: function (response) {
            //             if(response.kind =="success") {
            //                 if(response.data) {
            //                     $.ajax({
            //                         type: 'post',
            //                         url: '/ema/frontend/engine/engine.php',
            //                         data: {userID: response.data, action: "getUser"},
            //                         success: function (response) {
            //                             console.log(response);
            //                             $('.fullNameOutput').html(response.fullName);
            //                         }
            //                     });
            //                 }
            //             } else {

            //             }
            //         }
            //       });
            //   }, rules: {
            //         fullName: {
            //             minlength: 2,
            //             required: true
            //         },
            //         email: {
            //             required: true,
            //             email: true
            //         },
            //         birthday: {
            //             required: true,
            //             regx: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
            //         },
            //         alternativeZipcode: {
            //             minlength: 5,
            //             required: true
            //         },
            //         phone: {
            //             minlength: 5,
            //             required: true
            //         },
            //         "disclaimerCheck": {
            //             required: true,
            //             minlength: 1
            //         }
            //     }
            // });
            // $.validator.addMethod("regx", function(value, element, regexpr) {
            //     return regexpr.test(value);
            // }, "Please enter a valid pasword.");
            // $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
            //     var target = $(e.target).attr("href");

            // }

            // $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {

            //     var target = $(e.target).attr("href");
            //     currentTab = $(target).index();
            //     self.updateProgressBar($(target).index()+1);
            //     if(currentTab == 7) {
            //        $('.form-progress-status var').html(currentTab+1);
            //     }
            // });
            // $('.form-nav .arrow.right').click(function(){
            //     if(EMA.MODAL.isCurrentTabValid()){
            //         $('.nav-tabs > .nav-item > .active').parent().next('li').find('a').trigger('click');
            //     }
            // });

            // $('.form-nav .arrow.left').click(function(){
            //   $('.nav-tabs > .nav-item > .active').parent().prev('li').find('a').trigger('click');
            // });
            // $('.modal .btn-action:not(.submit)').click(function(i){
            //     var choice = $(this);

            //     if(currentTab != 1) {
            //         var input = $(this).parent().parent().parent().find("input[type=hidden]");
            //         input.val(choice.attr("data-value"));
            //     }
            //     if(currentTab == 1) {
            //         var input = $(this).parent().parent().parent().parent().find("input[type=hidden]");
            //         input.val(choice.attr("data-value"));
            //     }


            //     $(this).parent().parent().parent().find(".btn-action").each(function(i){
            //         $(this).removeClass("active");
            //         choice.addClass("active");
            //     })

            //     self.manageNavButtons();

            // })
            // $('.modal .btn-action.submit').click(function(i){
            //     var delayInMilliseconds = 250; //1 second

            //     if(EMA.MODAL.isCurrentTabValid()){
            //         setTimeout(function() {
            //           $('.nav-tabs > .nav-item > .active').parent().next('li').find('a').trigger('click');
            //         }, delayInMilliseconds);

            //     }
            // })
        },
        show: function(elem){
            // $('body,html').animate({
            //       scrollTop: 0

            // }, 1000);
            $('body').addClass("disable-scroll");
            $(elem).fadeIn();
            $(elem).find(".modal").delay(300).fadeIn();
        },
        hide: function(elem){

            $('body').removeClass("disable-scroll");
            $(elem).delay(300).fadeOut(100);
            $(elem).find(".modal").fadeOut(200);
        },
        updateProgressBar: function(index){
            var valeur = index * 11.11;
            $('.progress-bar').css('width', valeur+'%').attr('aria-valuenow', valeur);
            $('.form-progress-status var').html(index);
            console.log("updateProgressBar", currentTab);

            if(currentTab == 8) {
               $('.form-progress-status var').html(currentTab);
            }
        },
        validadeButtonRadioTab :function(formEl) {
            var isFilled = formEl.find("input")
            if(isFilled.val()) {
                return true;
            }
            return false;
        },
        validadeInputTab :function(formEl) {
            if(currentTab == 7) {
               EMA.MODAL.formSubmit();
            }
            return dinerocuentaForm.valid();
        },
        isCurrentTabValid: function(){

            var currentTabEl = $("#myTabContent").find("#form-"+(currentTab+1));

            var formType = currentTabEl.data("type");
            if(formType == "buttonRadioTabMultiOption") {
                currentTabEl = $("#myTabContent").find("#form-"+(currentTab+1)+" ."+requestTypeForm);
                return EMA.MODAL.validadeButtonRadioTab(currentTabEl.parent());
            }
            if(formType == "buttonRadioTab") {
                return EMA.MODAL.validadeButtonRadioTab(currentTabEl);
            }
            if(formType == "input") {
                return EMA.MODAL.validadeInputTab(currentTabEl);
            }

        },
        manageNavButtons: function(current){
            var delayInMilliseconds = 250; //1 second

            if(EMA.MODAL.isCurrentTabValid()){
                setTimeout(function() {
                  $('.nav-tabs > .nav-item > .active').parent().next('li').find('a').trigger('click');
                }, delayInMilliseconds);
            }
        }, formSubmit: function(){
            console.log("formSubmit")

            $(".form-nav .arrow").fadeOut();
            dinerocuentaForm.submit();

        }
    };
})();
EMA.DEMO = (function() {
    var appPixi;
    var self;
    var elem;
    var sprites;
    var appWidth;
    var appHeight;
    var videoSprite;
    var videoControler;
    var currentScene;
    var background;
    var globalScale;
    var canvas;
    const mainVolume = 0.05;
    var volumeState = true;
    var fullscreenState = false;
    
    

    const textStyle = new PIXI.TextStyle({
        fontFamily: "Open Sans",
        fontWeight: 100,
        fontSize: "13px",
        fill: "white"
    });
    const textStyleBold = new PIXI.TextStyle({
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "14px",
        align: "center",
        lineHeight: 14,
        fill: "white"
    });
    
    const textStyleBulkie = new PIXI.TextStyle({
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "20px",
        align: "center",
        lineHeight: 18,
        fill: "white",
        // stroke: "white",
        // strokeThickness: 4 
    });

    const hitAreaStyle = new PIXI.TextStyle({
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "140px",
        align: "center",
        lineHeight: 18,
        fill: "#e16b5a"
        // stroke: "white",
        // strokeThickness: 4 
    });
    var introScene;
    var level1Scene;
    var loadResources;
    var ticker;
    var tickerController = 0;
    // LESS is MORE 1 is minimum
    var sensitivyFactor = 4;
    
    var selectedCard; 
    var hitsContainer; 
    var fxSoundVolume = 10;
    var scoreContainer;
    var score = 0;
    var starsPerPoint = 5;

    var gameData = {
        "user":{
           "name":"João",
           "userID":1,
           "globalScore": 100,
           "gameScore": 45

        },
        "game":{
            "gameType": "Memória",
            "title":"V/F",
            "inputShapeSides":3,
            "instruction":"Vestibulum id ligula porta felis euismod semper.",
            "hitImage": "/frontend/contents/hit-card-area.png",
            "inputList":
                
                [{
                    "label":"V",
                    "matchLabel":1,
                    "image":"/frontend/contents/card-ventoinha.png",
                    "soundInput":"/frontend/contents/som-v.mp3",
                    "instructionVideo":"/frontend/contents/instructions-v.mp4"
                },{
                    "label":"F",
                    "matchLabel":2,
                    "image":"/frontend/contents/card-gato.png",
                    "soundInput":"/frontend/contents/som-f.mp3",
                    "instructionVideo":"/frontend/contents/instructions-f.mp4"
                }]
        }
     }
    class ScoreUI extends PIXI.Sprite {
        
        constructor(x, y, texture, scale){
            super(texture);
            this._score = 0;
            this.x = x;
            this.y  =y;
            
            this.scale.x = this.scale.y = scale;
            this.labelText;
            this.textStyleScore = new PIXI.TextStyle({
                fontFamily: "Open Sans",
                fontWeight: 600,
                fontSize: "60px",
                align: "#ffffff",
            });
            this.labelText = new PIXI.Text("00", this.textStyleScore);
            this.labelText.align = "center";
            this.addChild(this.labelText);
            this.labelText.align = "center";
            this.labelText.x = this.width+10;
            this.labelText.y = this.height;
        }
    
        get score() {
            return this._score;
        }
    
        set score(score) {
            var self = this;
            var counter = { var: self._score };
            TweenMax.to(counter, 2, {
                var: score, 
                onUpdate: function () {
                    self._score = Math.ceil(counter.var);
                    // console.log(self._score, counter.var);
                    self.labelText.text = self._score.toString().padStart(2, '0');
                    
                }, ease: Circ.easeOut, delay: .3
            });
        }
    }
    class ScoreStar extends PIXI.Sprite {
        
        constructor(x, y, texture, scale){
            super(texture);
            this.x = x;
            this.y  =y;
            //this.scale.x = this.scale.y = scale;
            this.labelText;
            this.anchor.set(0.5);
            // var rotation = getRandomArbitrary(0, 50);
            // var plusOrMinus = Math.random() < 0.5 ? -1 : 1;
            // rotation *= plusOrMinus;
            // this.rotation = rotation;
            this.scale.x = this.scale.y = 0;
            TweenMax.to(this, 1, {pixi: {scale: scale}, ease: Elastic.easeOut});
            
        }
        goto(x, y, delay, _sound = null) {
            var self = this;
            TweenMax.to(this, 1, {pixi: {x: x, y:y}, ease: Back.easeInOut, delay: delay, onComplete: function(){
                self.destroy();
            }, onStart: function(){
                if(_sound) {
                    var sound = new PIXI.sound.Sound.from(_sound);
                    sound.play();
                }
            }});
        }
    }
    class MyButton extends PIXI.Sprite {
        
        constructor(x, y, texture, texturePressed){
            super(texture);
            this.x = x;
            this.y  =y;
            this.anchor.set(0.5);
            this.interactive = true;
            this.buttonMode = true;
            // console.log(texture, texturePressed);
            this.textureButton = new PIXI.Texture.from(texture.textureCacheIds[1]);
            this.textureButtonDown = new PIXI.Texture.from(texturePressed.textureCacheIds[1]);
            this.on('mousedown', this.onButtonDown);
            this.on('touchstart', this.onButtonDown);
            this.on('mouseup', this.onButtonUp);
            this.on('touchend', this.onButtonUp);
            this.on('mouseupoutside', this.onButtonUp);
            this.on('touchendoutside', this.onButtonUp);
            this.on("pointerout", this.onButtonUp)
            // this.isdown = false;
        }
        onButtonDown(){
            // this.isdown = true;
            this.texture = this.textureButtonDown;
        }
        onButtonUp(){
            this.texture = this.textureButton;
            // this.isdown = false;
        }
        
    }
    class DraggableCard extends PIXI.Sprite {
        
        constructor(x, y, texture, scale = 0.5, rotation = 0, _hitsContainer = null){
            super(texture);
            this._hitsContainer = _hitsContainer;
            this._id;
            this.matched = false;
            this._score = 0;
            
            // console.log(this);
            if(rotation != 0) {
                var plusOrMinus = Math.random() < 0.5 ? -1 : 1;
                rotation *= plusOrMinus;
            }
            this.x = x;
            this.y = y;
            this.rotation = rotation;
            this.scaleOrigin = scale;
            this.scale.x = this.scale.y = 0;
            this.anchor.set(0.5);
            this.interactive = true;
            this.buttonMode = true;
            this.on("pointerover", this.onOverStart)
            this.on("pointerout", this.onOut)
            this.on("pointerdown", this.onDragStart)
            this.on("pointerup", this.onDragEnd)
            this.on("pointerupoutside", this.onDragEnd)
            this.on("pointermove", this.onDragMove); 
            this.mainShadow = new PIXI.filters.DropShadowFilter();
            this.mainShadow.color = 0x000020;
            this.mainShadow.alpha = 0.2;
            this.mainShadow.blur = 3;
            this.mainShadow.distance = 5;
            this.mainShadow.quality = 10;
            this.mainShadow.rotation = 90;
            this.filters = [this.mainShadow];
            
            
            TweenMax.to(this, .6, {pixi:{scale:this.scaleOrigin}, ease: Elastic.easeOut});
            // console.log("_hitsContainer",this._hitsContainer);
            
            
        }

        // SETTERS AND GETTERS
        get id() {
            return this._id;
        }
        set id(id) {
            this._id = id;
        }
        get matched() {
            return this._matched;
        }
        set matched(matched) {
            this._matched = matched;
        }
        get score() {
            return this._score;
        }
        set score(score) {
            this._score = score;
            if(this._score <= 0) {
                this._score = 1;
            }
        }
        
        
        onOverStart(event) {
            // this.data = event.data;  
            // this.lastPosition = this.data.getLocalPosition(this.parent);
            // this.filters = [mainShadowOver];
            
            TweenMax.to(this, .1, {pixi:{scale:this.scaleOrigin+0.01}, ease: Quad.easeOut});
            // self.bringToFront(this, level1Scene);
            // console.log(this.id);
        }

        onDragStart(event) {
            if(!this.matched) {
                this.data = event.data;  
                this.lastPosition = this.data.getLocalPosition(this.parent);
                
                TweenMax.to(this, .1, {pixi:{scale:this.scaleOrigin+0.03, rotation: 0}, ease: Quad.easeOut});
                self.bringToFront(this, level1Scene);
                selectedCard = this;
                ticker.start();
                tickerController = 0;
            
                TweenMax.to(this.mainShadow, .1, {
                    blur: 5,
                    distance: 10,
                    alpha: 0.3
                });
            }
        }
            
        onDragMove() {
            if (this.lastPosition) {
                var newPosition = this.data.getLocalPosition(this.parent);    
                this.position.x += (newPosition.x - this.lastPosition.x);
                this.position.y += (newPosition.y - this.lastPosition.y);
                this.lastPosition = newPosition;
            }
        }
        
        onDragEnd() {
            this.data = null;
            this.lastPosition = null;
            var _this = this;
            TweenMax.to(this, .1, {pixi:{scale:this.scaleOrigin}, ease: Quad.easeOut, onComplete:function(){
                // _this.filters = [mainShadow];
            }});
            TweenMax.to(this.mainShadow, .1, {
                blur: 3,
                distance: 5,
                alpha: 0.2
            });
            ticker.stop();
            
            selectedCard = null;
            this.data = null;
            
            
            
        }
        onOut() {
            var _this = this;
            TweenMax.to(this, .1, {pixi:{scale:this.scaleOrigin}, ease: Quad.easeOut, onComplete:function(){
                // _this.filters = [mainShadow];
            }});
            TweenMax.to(this.mainShadow, .1, {
                blur: 3,
                distance: 5,
                alpha: 0.2
            });

        }
    }
    class PlayList  extends PIXI.Container {
        constructor(playlist, showVideo = false) {
            super();
            this.current = 0;
            // this.self = this;
            var thisClass = this;
            this.playlist = playlist;
            this.showVideo = showVideo;
            
            this.id = self.getIdenfierNameFromFilename(this.playlist[1].instructionVideo);
            this.instruction = this.id+this.playlist[1].matchLabel;
            this.interactive = true;
            this.buttonMode = true;
            // console.log(loadResources[this.instruction]);
            // this.playVideo();
            // const loader = new PIXI.Loader(); // you can also create your own if you want

            // var videoSrc = loadResources[this.instruction].url;
            // loader.add(this.id, '/frontend/contents/jogar-small.png');
            // loader.load((loader, resources) => {  
            //     console.log(resources);
            // })

            
            // Chainable `add` to enqueue a resource
            
            this.texture = new PIXI.Texture.from(loadResources[this.instruction].url);
            this.videoSprite = new PIXI.Sprite( this.texture );
            
            this.videoSprite.scale.x = this.videoSprite.scale.y = 1;
            this.videoSprite.anchor.set(0.5);
            this.videoSprite.x = appWidth / 2;
            this.videoSprite.y = appHeight / 1.8;
            this.mainShadow = new PIXI.filters.DropShadowFilter();
            this.mainShadow.color = 0x000020;
            this.mainShadow.alpha = 0.2;
            this.mainShadow.blur = 3;
            this.mainShadow.distance = 5;
            this.mainShadow.quality = 10;
            this.mainShadow.rotation = 90;
            this.filters = [this.mainShadow];
            
            this.addChild(this.videoSprite);
            this.videoControler = this.videoSprite.texture.baseTexture.resource.source;
            this.videoControler.removeEventListener('ended', videoEndHandler);   
            this.videoControler.removeEventListener('playing', videoPlayHandler);   

            this.videoControler.addEventListener('ended', videoEndHandler);   
            this.videoControler.addEventListener('playing', videoPlayHandler);
            
            // console.log(this.videoSprite, videoControler);

            this.videoControler.currentTime = 0;  

            this.alpha = 0;
            var _this = this;
            TweenMax.to(this, .3, {pixi: {alpha: 1}, ease: Quad.easeOut, onComplete: thisClass.playVideo, onCompleteParams: [thisClass]});
            console.log(this);
            
            function videoPlayHandler(event){
                console.log("playing", thisClass)
                // thisClass.playSound(thisClass.playlist[thisClass.current].soundInput);
                // console.log(event);
            }
            function videoEndHandler(event){
                TweenMax.to(_this, .3, {pixi: {alpha: 0}, ease: Quad.easeOut, onComplete: function(){
                    console.log("ended?")
                    thisClass.videoControler.removeEventListener('ended', thisClass.videoEndHandler);   
                    thisClass.videoControler.removeEventListener('playing', thisClass.videoPlayHandler);
                    ticker.stop();
                    thisClass.destroy();
                }});
                
                // console.log(event);
            }
        }
        
        playVideo(self) {
            ticker.start();
            
            self.videoControler.currentTime = 0;  
            // self.videoControler.pause();  
            self.videoControler.play();
        }
        
        playSound(soundInput) {
            var self = this;
            var playlist = this.playlist;
            var current = this.current;
            var showVideo = this.showVideo;
            PIXI.sound.Sound.from({
                url: soundInput,
                preload: true,
                loaded: function(err, sound) {
                    const instance = sound.play();
                    instance.on('end', soundHandler);
                    
                }
            });          
            function soundHandler(){
                current++;
                if(current < playlist.length) {
                    self.playSound(playlist[current].soundInput);       
                }
                if(current == playlist.length) {
                    TweenMax.to(self, .4, {pixi: {alpha: 0}, ease: Quad.easeOut, onComplete: function(){
                        self.videoControler.removeEventListener('ended', self.videoEndHandler);   
                        self.videoControler.removeEventListener('playing', self.videoPlayHandler);   
                        self.destroy();
                    }});
                    
                }
            }
            this.current++;
         }
    }
        
    class HitArea extends PIXI.Sprite {
        constructor(x, y, texture, scale = 0.5, text = ""){
            
            super(texture);
            this._id;
           
            this.x = x;
            this.y = y;
            
            this.scaleOrigin = scale;
            this.scale.x = this.scale.y = scale;
            // this.anchor.set(0.5);
            // TweenMax.to(this, .6, {pixi:{scale:this.scaleOrigin}, ease: Elastic.easeOut});
            // this._localBounds = this._bounds;
            // this._localBoundsRect = this._bounds;
            
            var labelText = new PIXI.Text(text, hitAreaStyle);
            labelText.anchor.set(0.5);
           
            
            this.addChild(labelText);
            labelText.x = this.width - 10;
            labelText.y = this.height+ 45;
            
        }
        get id() {
            return this._id;
        }
        set id(id) {
            this._id = id;
        }
    }
    return {
        init: function(elem) {
            self = this;
            elem = elem;
 
            console.log(gameData);
           
            canvas = document.getElementById("jogo");
            $("#jogo").trigger("pointertap");
            window.addEventListener('resize', this.resize);
            appWidth = $("#jogo").parent().parent().width();
            appHeight = $("#jogo").parent().parent().height();
    
            // document.addEventListener('fullscreenchange', function(e){
            //     console.log("fullscreenchange",e)
            // })
            // PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

            self.appPixi = new PIXI.Application({
                width: appWidth, 
                height: appHeight, 
                autoDensity: true, 
                resolution: 2,
                transparent: true,
                view: canvas,
                antialias: true,
                resolution: devicePixelRatio || 1
            });
            const defaultIcon = "url('/frontend/contents/mouse-pointer-small.png'), auto";
            const hoverIcon = "url('/frontend/contents/mouse-pointer-small.png'), auto";

            // Add custom cursor styles
            self.appPixi.renderer.plugins.interaction.cursorStyles.default = defaultIcon;
            self.appPixi.renderer.plugins.interaction.cursorStyles.pointer = hoverIcon;
            
            document.getElementById("demo").appendChild(self.appPixi.view);
            
            const loader = new PIXI.Loader(); // you can also create your own if you want

            sprites = {};
            var videoSrc = '/frontend/contents/fonosteps-intro-720.mov';
            var isChrome = ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) &&(navigator.vendor.toLowerCase().indexOf("google") > -1));
            
            if(isChrome) {
                var videoSrc = '/frontend/contents/fonosteps-intro-720.mp4';
            }
            
            // Chainable `add` to enqueue a resource
            loader.add('playBtn', '/frontend/contents/jogar-small.png')
                .add('helpBtn', '/frontend/contents/help-btn.png')
                .add('textBox', '/frontend/contents/text-box.png')
                .add('introMovie', videoSrc)
                .add('soundClick', '/frontend/contents/btn-click.mp3')
                .add('music', '/frontend/contents/background-music.mp3')
                .add('btnHover', '/frontend/contents/btn-hover.mp3')
                .add('background', '/frontend/contents/background.png')
                .add('glow', '/frontend/contents/white-glow-reflex.png')
                .add('mousePointer', '/frontend/contents/mouse-pointer.png')
                .add('fullscreenBtn', '/frontend/contents/fullscreen-btn.png')
                .add('musicBtn', '/frontend/contents/music-btn.png')
                // .add('cardVentoinha', '/frontend/contents/card-ventoinha.png')
                // .add('cardGato', '/frontend/contents/card-gato.png')
                // .add('hitCard', '/frontend/contents/hit-card-area.png')
                .add('soundPlay', '/frontend/contents/sound-play.png')
                .add('soundPlayPressed', '/frontend/contents/sound-play-pressed.png')
                .add('pointSound', '/frontend/contents/earn-point.wav')
                .add('softFailSound', '/frontend/contents/soft-fail.wav')
                .add('legoHead', '/frontend/contents/girl-svgrepo-com.svg')
                .add('starScore', "/frontend/contents/star.png")
                .add('starSound', "/frontend/contents/star.mp3")

                // ADD GAME RESOURSES TO LOAD
                for(let i = 0; i < gameData.game.inputList.length; i++) {
                    let input = gameData.game.inputList[i];

                    if(input.image != "") {
                        let id = self.getIdenfierNameFromFilename(input.image);
                        loader.add(id+input.matchLabel, input.image);
                    }

                    if(input.soundInput != "") {
                        id = self.getIdenfierNameFromFilename(input.soundInput);
                        loader.add(id+i, input.soundInput);
                    }

                    if(input.instructionVideo != "") {
                        id = self.getIdenfierNameFromFilename(input.instructionVideo);
                        loader.add(id+input.matchLabel, input.instructionVideo);
                    }
                }
                let id = self.getIdenfierNameFromFilename(gameData.game.hitImage);
                loader.add(id, gameData.game.hitImage);
                

                

            
            loader.load((loader, resources) => {  
                loadResources = resources;
                // console.log(resources.introMovie, resources.playBtn.texture);
                sprites.playBtn = new PIXI.Sprite.from(resources.playBtn.texture);
                sprites.helpBtn = new PIXI.Sprite.from(resources.helpBtn.texture);
                sprites.textBox = new PIXI.Sprite.from(resources.textBox.texture);
                // console.log(resources.textBox.texture.baseTexture.resource);
                sprites.background = new PIXI.Sprite.from(resources.background.texture);
                sprites.glow = new PIXI.Sprite.from(resources.glow.texture);
                if(isChrome) {
                    sprites.introMovie = new PIXI.Texture.from(resources.introMovie.data);
                } else {
                    sprites.introMovie = new PIXI.Texture.from(resources.introMovie.url);
                }
                sprites.soundClick = new PIXI.sound.Sound.from(resources.soundClick.url);
                sprites.pointSound = new PIXI.sound.Sound.from(resources.pointSound.url);
                sprites.softFailSound = new PIXI.sound.Sound.from(resources.softFailSound.url);
                
                sprites.btnHover = new PIXI.sound.Sound.from(resources.btnHover.url);
                sprites.music = new PIXI.sound.Sound.from(resources.music.url);
                sprites.fullscreenBtn = new PIXI.Sprite.from(resources.fullscreenBtn.texture);
                sprites.musicBtn = new PIXI.Sprite.from(resources.musicBtn.texture);
                // sprites.hitCard = new PIXI.Sprite.from(resources.hitCard.texture);
                // sprites.soundPlay = new PIXI.Sprite.from(resources.soundPlay.texture);
                
                sprites.starScore = new PIXI.Sprite.from(resources.starScore.texture);
                // sprites.starSound = new PIXI.sound.Sound.from(resources.starSound.url);
                
                // sprites.cardVentoinha = new PIXI.Sprite.from(resources.cardVentoinha.texture);
                
                
            });
            videoSprite = sprites.introMovie;
            var loaderUI = new PIXI.Container();
            var graphics = new PIXI.Graphics();

            graphics.lineStyle(2, 0xf6ba6e, 1);
            
            graphics.lineTo(0, 2);
            
            loaderUI.addChild(graphics);
            loaderUI.x = appWidth/2;
            loaderUI.y = appHeight/2;
            
            
            self.appPixi.stage.addChild(loaderUI);

            //TweenMax.to(graphics, 1, {width: 600});

            // throughout the process multiple signals can be dispatched.
            loader.onProgress.add((e) => {
                // console.log(e.progress, (e.progress*600/100));
                TweenMax.to(graphics, 1, {width: (e.progress*600/100)});
                // if(e.progress == 100) {
                    
                // }
            }); // called once per loaded/errored file
            loader.onError.add(() => {}); // called once per errored file
            loader.onLoad.add(() => {}); // called once per loaded file
            loader.onComplete.add(() => { 
                TweenMax.to(graphics, .4, {width: 0, onComplete: function(){
                    self.appPixi.stage.removeChild(loaderUI);
                    self.introIn();  
                }, ease: Elastic.easeInOut});
                
             }); // called once when the queued resources all load.
            
        },
        
        introIn: function() {
            
            sprites.music.play({loop: true});
            sprites.music.volume = mainVolume;
            
            // ADD BG
            var background = sprites.background;
            background.width = appWidth;
            background.height = appHeight;
            self.appPixi.stage.addChild(background);
            
            // CREATE INTRO SCENE
            introScene = new PIXI.Container();
            self.appPixi.stage.addChild(introScene);

            

            // ADD INTRO MOVIE
            videoSprite = new PIXI.Sprite( sprites.introMovie);
            videoSprite.anchor.set(0.5);
            videoSprite.scale.x = videoSprite.scale.y = .7;
            videoSprite.width = appWidth;
            videoSprite.height = appHeight;
            videoSprite.x = appWidth / 2;
            videoSprite.y = appHeight / 2;
            
            // ADD GLOW
            var glow = sprites.glow;
            glow.width = appWidth;
            glow.height = appHeight;
            glow.y = -(appHeight / 2);
            self.appPixi.stage.addChild(glow);
            
            
            videoControler = videoSprite.texture.baseTexture.resource.source;
            // console.log("videoControler",videoSprite.texture.baseTexture.resource.source)
            videoControler.currentTime = 0;
            videoControler.play();
            videoControler.pause();

            // ADD TEXT WARNING
            // const userInstruction = new PIXI.Text('SELECCIONA\nUM UTILIZADOR', textStyleBulkie);
            // userInstruction.anchor.set(0.5);
            // userInstruction.x = appWidth / 2;
            // userInstruction.y = (appHeight /2 ) ;
            // introScene.addChild(userInstruction);
            // userInstruction.scale.x =  userInstruction.scale.y = 0;
            // TweenMax.to(userInstruction, 1, {pixi:{scale:1.3}, ease: Elastic.easeOut, delay: 0.5});

            // ADD PLAYBTN
            var playBtn = sprites.playBtn;
            playBtn.scale.x = playBtn.scale.y = 0;
            introScene.addChild(playBtn);
            playBtn.x = (appWidth / 2);
            playBtn.y = (appHeight / 2);
            playBtn.anchor.set(0.5);
            playBtn.interactive = true;
            playBtn.buttonMode = true;

            
            // for(let i = 0; i < 10000; i++) {
            //     var lego = new PIXI.Sprite.from(loadResources.legoHead.texture);
            //     introScene.addChild(lego);
            //     lego.x = getRandomInt(0, appWidth);
            //     lego.y = getRandomInt(0, appHeight);
            //     lego.anchor.set(0.5)
            //     lego.interactive = true;

            //     TweenMax.to(lego, 1, {pixi:{scale:getRandomArbitrary(2, 5)}, ease: Elastic.easeOut, delay: getRandomArbitrary(0, 1)});
            //     lego.on("pointerover", function(){
            //         TweenMax.to(this, 1, {pixi:{scale:getRandomInt(1, 5)}, ease: Elastic.easeOut, overwrite: true});
            //     })
            // }


            // ANIMATE PLAYBTN
            TweenMax.to(playBtn, 1, {pixi:{scale:0.7}, ease: Elastic.easeOut, delay: 0.2});
            playBtn.on("mouseover", function(){
                TweenMax.to(playBtn, 1, {pixi:{scale:.9}, ease: Elastic.easeOut});
                sprites.btnHover.play({volume: 0.1});
            }).on("mouseout", function(){
                TweenMax.to(playBtn, 1, {pixi:{scale:.7}, ease: Elastic.easeOut});
            })

            // PLAY PRESS
            playBtn.on("pointertap", function(){
                
                //console.log("plim")
                introScene.addChild(videoSprite);
                playBtn.bringToFront = self.bringToFront(playBtn, introScene);
                glow.bringToFront = self.bringToFront(glow, self.appPixi.stage);
                sprites.fullscreenBtn.bringToFront = self.bringToFront(sprites.fullscreenBtn, self.appPixi.stage);
                sprites.musicBtn.bringToFront = self.bringToFront(sprites.musicBtn, self.appPixi.stage);
                videoControler.play();
                
                
                videoControler.addEventListener('ended', (event) => {
                    


                    self.introOut();
                });
                videoControler.addEventListener('play', (event) => {
                
                //console.log("video start")
                });
                playBtn.removeAllListeners()
                sprites.soundClick.play();
                TweenMax.to(playBtn, 1, {pixi:{scale:0}, ease: Elastic.easeIn, overwrite:'all'});
                // TweenMax.to(videoSprite, .3, {pixi:{alpha:1}});
            });
            //ADD GENERIC SETTINGS
            self.addGenericSettings();
        },
        introOut: function(){
            
            // console.log(introScene.children);
            
            TweenMax.to(videoSprite, .4, {pixi:{alpha:0},delay: 1, onComplete: function(){
                
                // Destroy elements
                introScene.removeChild(videoSprite);
                videoSprite.destroy();
                // videoControler.destroy();

                self.level1In();
                self.addHelpBtn();
                
                self.appPixi.stage.removeChild(introScene);
                self.appPixi.stage.addChild(introScene);

            }});

            // TweenMax.to(introScene, 2, {pixi:{x:-appWidth/2}, ease: Back.easeIn, delay: 1, onComplete: function(){

            // }});
           
        },
        addHelpBtn: function(){
            
            // ADD HELPBTN
            var helpBtn = sprites.helpBtn;
            helpBtn.scale.x = helpBtn.scale.y = .2;
            helpBtn.x = appWidth - 40;
            helpBtn.y = appHeight - 40;
            helpBtn.anchor.set(0.5);
            helpBtn.interactive = true;
            helpBtn.buttonMode = true;
            
            self.appPixi.stage.addChild(helpBtn); 
            helpBtn.scale.x = helpBtn.scale.y = 0;
            self.openHelpBox();
            TweenMax.to(helpBtn, 1, {pixi:{scale:0.2}, ease: Elastic.easeOut, delay: 1, onComplete: function(){
                
            }});
            helpBtn.on("mouseover", function(){
                TweenMax.to(helpBtn, 1, {pixi:{scale:.3}, ease: Elastic.easeOut});
                sprites.btnHover.play({volume: 0.1});
            }).on("mouseout", function(){
                TweenMax.to(helpBtn, 1, {pixi:{scale:.2}, ease: Elastic.easeOut});
            }).on("pointertap", function(){
                sprites.soundClick.play();
                if(currentScene == "help") {
                    self.closeHelpBox(); 
                } else {
                    self.openHelpBox(); 
                }
            })
        },
        level1In: function(){
            level1Scene = new PIXI.Container();
            hitsContainer = new PIXI.Container();

            // ADD FIRST SCENE
            self.appPixi.stage.addChild(level1Scene);
            level1Scene.addChild(hitsContainer);
            
            hitsContainer.x = appWidth /2;
            hitsContainer.y = appHeight /1.8;
            
            console.log(gameData.game.inputList);
            gameData.game.inputList = shuffleArray(gameData.game.inputList);
            console.log(gameData.game.inputList);
            var soundPlay = new MyButton((appWidth/2), 100, loadResources.soundPlay.texture, loadResources.soundPlayPressed.texture)
            soundPlay.scale.x = soundPlay.scale.y = 0;
            soundPlay.on("pointertap", function(){
                sprites.soundClick.play();
                var playList = new PlayList(gameData.game.inputList);
                level1Scene.addChild(playList);
                // playList.x = 0;
                // playList.y = 0;
                // playList.render();
                // playList.playVideo();
            })


            
            
            for(let i = 0; i < gameData.game.inputList.length; i++) {
                let inputData = gameData.game.inputList[i];
                
                var identifier = self.getIdenfierNameFromFilename(inputData.image)+(inputData.matchLabel);
                var card = new DraggableCard(getRandomInt((appWidth/2)-110, (appWidth/2)+110), appHeight / 2.6, loadResources[identifier].texture, 0.5, 0, hitsContainer);
                card.id = identifier;
                card.score = starsPerPoint;
                console.log(card.score);
                level1Scene.addChild(card);
                
                
                let marginX = 50;
                let textureID = self.getIdenfierNameFromFilename(gameData.game.hitImage);
                if(i == 0) {
                    marginX = 0;
                }
                let hitArea = new HitArea(((154+marginX) * i), 0, loadResources[textureID].texture, 0.53, (i+1));                
                hitsContainer.addChild(hitArea);
                hitArea.id = identifier;
            }
            // DISPLAY RANDOM DEPTH ORDER
            let random = getRandomInt(0, gameData.game.inputList.length-1);
            let randomObj = level1Scene.getChildAt(random);
            self.bringToFront(randomObj, level1Scene);
            // DISPLAY RANDOM DEPTH ORDER

            // level1Scene.cacheAsBitmap = true;
            level1Scene.addChild(soundPlay);
            var hitsContainerNewX = (appWidth / 2) - (hitsContainer.width/2);
            hitsContainer.x = hitsContainerNewX;

            


            TweenMax.to(soundPlay, 1, {pixi:{scale:0.25}, ease: Elastic.easeOut, delay: .3});

            var hits = self.recursiveFindSprites(hitsContainer);
            
           
            ticker = PIXI.Ticker.shared;
            ticker.autoStart = false;
            ticker.add(function (time) {
                console.log("run ticker", time);
                for(let i = 0; i < hits.length; i++) {
                    let hit = hits[i];
                    if(self.checkSnap(selectedCard, hit, sensitivyFactor) && selectedCard.id == hit.id) {
                        if(!selectedCard.matched) {
                            var _selectedCard = selectedCard;
                            ticker.stop();
                            self.snapIn(selectedCard,hit);
                            console.log("match");
                            sprites.pointSound.stop();
                            sprites.pointSound.play({start: 0});
                            score += _selectedCard.score;
                            scoreContainer.score = score;
                            let _bounds = hit.getBounds();
                            var radius = 100;
                            console.log("_selectedCard.score",_selectedCard.score);
                            
                            for(let s = 0; s < (_selectedCard.score); s++) {
                                var pt_angle = Math.random() * 2 * Math.PI;
                                var pt_radius_sq = Math.random() * radius * radius;
                                var _x = (Math.sqrt(pt_radius_sq) * Math.cos(pt_angle))+_bounds.x + (_selectedCard.width / 2);
                                var _y = (Math.sqrt(pt_radius_sq) * Math.sin(pt_angle))+_bounds.y + (_selectedCard.height / 2);

                                var star = new ScoreStar(_x, _y, loadResources.starScore.texture, .2);
                                level1Scene.addChild(star);
                                star.goto(scoreContainer.x+(star.width / 2), scoreContainer.y+(star.height/2), (s * 0.15),loadResources.starSound.url);
                                // star.rotate();
                            }
                            self.bringToFront(_selectedCard, level1Scene);
                            _selectedCard.matched = true;
                        }
                        

                    } else if(self.checkSnap(selectedCard, hit, sensitivyFactor)) {
                        
                        
                        var _selectedCard = selectedCard;
                        tickerController++;
                        if (tickerController>=10) {
                            console.log("snap not match", tickerController);
                            ticker.stop();
                            tickerController = 0;

                            sprites.softFailSound.stop();
                            sprites.softFailSound.play({start: 0});
                            _selectedCard.score -= 1; 
                            console.log("perdeu estrela", _selectedCard.score);
                            TweenMax.to(_selectedCard, .7, {pixi:{y:getRandomInt(190,270), x: _selectedCard.x, rotation: 0}, ease: Back.easeOut, delay: .4, overwrite: true});
                        }
                        
                        // TweenMax.to(selectedCard, .5, {pixi:{rotation:"+=30"}, yoyo:true,ease: Elastic.easeOut});
                        var plusOrMinus = Math.random() < 0.5 ? -1 : 1;
                        
                        var newRotation = getRandomArbitrary(0,10) * plusOrMinus;
                        
                        
                        TweenMax.to(selectedCard, .2, {pixi:{rotation:newRotation}, ease: Elastic.easeOut, onComplete: function(){
                            tickerController = 10;
                        }});
                        
                    }
                }
            });
            self.bringToFront(scoreContainer, self.appPixi.stage);
            ticker.stop();

        },
        addScore: function(x = 0, y = 0){
            scoreContainer = new ScoreUI(x, y, loadResources.starScore.texture, .23);
            self.appPixi.stage.addChild(scoreContainer);
            
            
        },
        bringToFront: function(sprite, parent) {
            var sprite = (typeof(sprite) != "undefined") ? sprite.target || sprite : this;
            var parent = parent || sprite.parent || {"children": false};
            if (parent.children) {    
                for (var keyIndex in sprite.parent.children) {         
                    if (sprite.parent.children[keyIndex] === sprite) {            
                        sprite.parent.children.splice(keyIndex, 1); 
                        break;       
                    }    
                }    
                parent.children.push(sprite);
            }
        },
        alignCenter: function(_sprite, _width, _height) {

        },
        resizeSprite(_sprite, _newWidth) {
            console.log(_sprite);
            var oldHeight;
            var oldWidth;

            oldHeight = _sprite._texture.orig.height;
            oldWidth = _sprite._texture.orig.width;
        


            // console.log( _sprite._texture._frame.height);
            _sprite.width = _newWidth;
            var _newHeight = Math.round(_newWidth * oldHeight / oldWidth);
            _sprite.height = _newHeight;
            console.log(_newHeight);
        },
        openHelpBox: function(){
            currentScene = "help";
            var helpBtn = sprites.helpBtn;
            // text box
            var textBox = sprites.textBox;
            textBox.interactive = true;
            textBox.buttonMode = true;
            textBox.anchor.set(0.5, 0.5);

            self.appPixi.stage.addChild(textBox);
            self.alignCenter(textBox);
            
            self.resizeSprite(textBox, appWidth / 1.3);
            var textBoxWidth = textBox.width;
            var textBoxHeight= textBox.height;
            textBox.x = appWidth / 2;
            textBox.y = appHeight / 2.5;
            textBox.width = textBox.height = 0;
            TweenMax.to(textBox, 1, {pixi:{width: textBoxWidth, height: textBoxHeight}, ease: Elastic.easeOut});
            
            
            textBox.on("mouseover", function(){
                // TweenMax.to(textBox, 1, {pixi:{width: textBoxWidth+20, height: textBoxHeight+20}, ease: Elastic.easeOut});
                // soundBtnHover.play();
                TweenMax.to(helpBtn, 1, {pixi:{scale:.3}, ease: Elastic.easeOut});
            }).on("mouseout", function(){
                // TweenMax.to(textBox, 1, {pixi:{width: textBoxWidth, height: textBoxHeight}, ease: Elastic.easeOut});
                TweenMax.to(helpBtn, 1, {pixi:{scale:.2}, ease: Elastic.easeOut});
            });
            textBox.on("pointertap", function(){
                self.closeHelpBox();
            })
        },
        closeHelpBox: function(){
            currentScene = "";
            var textBox = sprites.textBox;
            TweenMax.to(textBox, .7, {pixi:{scale: 0}, ease: Back.easeIn, overwrite: true,  onComplete: function(){
                self.appPixi.stage.removeChild(textBox);
            }})
            
        },
        addGenericSettings: function(){
            
            self.appPixi.stage.addChild(sprites.fullscreenBtn);
            self.buttonConfig(sprites.fullscreenBtn);
            sprites.fullscreenBtn.x = 40;
            sprites.fullscreenBtn.y = appHeight -30;
            sprites.fullscreenBtn.scale.x = sprites.fullscreenBtn.scale.y = .2;

            sprites.fullscreenBtn.on("mouseover", function(){
                TweenMax.to(sprites.fullscreenBtn, 1, {pixi:{scale:.25}, ease: Elastic.easeOut});
                sprites.btnHover.play({volume: 0.1});
            }).on("mouseout", function(){
                TweenMax.to(sprites.fullscreenBtn, 1, {pixi:{scale:.2}, ease: Elastic.easeOut});
            });
            sprites.fullscreenBtn.on("pointertap", function(){
                self.requestFullScreen(canvas);
            })


            self.appPixi.stage.addChild(sprites.musicBtn);
            self.buttonConfig(sprites.musicBtn);
            sprites.musicBtn.x = 90;
            sprites.musicBtn.y = appHeight -30;
            sprites.musicBtn.scale.x = sprites.musicBtn.scale.y = .2;

            sprites.musicBtn.on("mouseover", function(){
                TweenMax.to(sprites.musicBtn, 1, {pixi:{scale:.25}, ease: Elastic.easeOut});
                sprites.btnHover.play({volume: 0.1});
            }).on("mouseout", function(){
                TweenMax.to(sprites.musicBtn, 1, {pixi:{scale:.2}, ease: Elastic.easeOut});
            });
            sprites.musicBtn.on("pointertap", function(){
                // console.log(sprites.music.volume);
                
                if(volumeState) {
                    sprites.music.volume = 0;
                    volumeState = false;
                    sprites.musicBtn.alpha = .3;

                }else {
                    sprites.music.volume = mainVolume;
                    volumeState = true;
                    sprites.musicBtn.alpha = 1;
                }
            })
            const footerTitle = new PIXI.Text('ATIVIDADES DE PROCESSAMENTO FONOLÓGICO', textStyleBold);
            footerTitle.anchor.set(0.5);
            footerTitle.x = appWidth / 2;
            footerTitle.y = appHeight -38;
            self.appPixi.stage.addChild(footerTitle);
            footerTitle.alpha = 0;
            TweenMax.to(footerTitle, 1, {pixi:{alpha:1}, ease: Quad.easeOut, delay: 1});
            
            const text = new PIXI.Text('por Ângela Silvino e Tânia Reis', textStyle);
            text.anchor.set(0.5);
            text.x = appWidth / 2;
            text.y = appHeight -20;
            self.appPixi.stage.addChild(text);
            text.alpha = 0;
            TweenMax.to(text, 1, {pixi:{alpha:1}, ease: Quad.easeOut, delay: 1.2});

            self.addScore(15, 10);

            
            
        },
        buttonConfig: function(_sprite){
            _sprite.interactive = true;
            _sprite.buttonMode = true;
            _sprite.anchor.set(0.5, 0.5);
        },
        requestFullScreen: function(element) {
            
            if(!fullscreenState) {
                fullscreenState = true;
                if(element.requestFullscreen) {
                    element.requestFullscreen();
                } else if(element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if(element.webkitRequestFullscreen) {
                    element.webkitRequestFullscreen();
                } else if(element.msRequestFullscreen) {
                    element.msRequestFullscreen();
                }
                setTimeout(function(){
                    $("#jogo").width(window.innerWidth);
                    $("#jogo").height(window.innerHeight);
                }, 500);
                
            } else {
                fullscreenState = false;
                if(document.exitFullscreen) {
                    document.exitFullscreen();
                } else if(document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if(document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                }
                setTimeout(function(){
                    $("#jogo").width($("#jogo").parent().parent().width());
                    $("#jogo").height($("#jogo").parent().parent().height());
                }, 500);
            }
        },
        checkSnap: function(a, b, sensitivyFactor) {
            if (a) { 
                var ab = a.getBounds();
                var bb = b.getBounds();
                return ab.x + (ab.width/sensitivyFactor) > bb.x && ab.x < bb.x + (bb.width/sensitivyFactor) && ab.y + (ab.height/sensitivyFactor) > bb.y && ab.y < bb.y + (bb.height/sensitivyFactor);
            }
            
        },
        snapIn: function(a, b){
            // console.log(b);
            TweenMax.to(a, 1, {pixi:{x:b._bounds.minX + (a.width / 2), y:(b._bounds.minY+ (a.height / 2)+3)}, ease: Elastic.easeOut});
            a.onDragEnd();
        },
        recursiveFindSprites: function(displayObject ) {
            var spriteArray = new Array();
            // if ( displayObject.texture ) {
                
            // }
            // console.log(displayObject.children);
        
            if ( Array.isArray( displayObject.children ) ) {
                for ( let i = 0; i < displayObject.children.length; ++i ) {
                    self.recursiveFindSprites(displayObject.children[i] );
                    spriteArray.push( displayObject.children[i] );
                }
            }
            return spriteArray;
        },
        getIdenfierNameFromFilename: function(filemane) {
            let ext = capitalize(getFileExtension(filemane));
            let name = string_to_slug(getFileNameNoExtension(filemane));
            return name+ext;
        },
        resize: function(e) { 
            if(!fullscreenState) {
                setTimeout(function(){
                    $("#jogo").width($("#jogo").parent().parent().width());
                    $("#jogo").height($("#jogo").parent().parent().height());
                    //console.log("aqui resize")
                }, 100);   
            }
        }   
    }
})();
EMA.COOKIESMODAL = (function() {
    return {
        init: function(elem) {
            var self = this;
            if($.cookie('cookies')) {
                $(elem).hide();
            }
            $(elem).find('.close-modal').on('click',function(){
                self.hideCookies(elem);
            });
        },
        hideCookies: function(elem){
            $.cookie('cookies', 1);
            TweenMax.to($(elem), {bottom: -200, duration: .8, ease: Back.easeIn})
            // $(elem).animate({
            //       bottom: -200
            //   }, {
            //  duration: 800,
            //  easing: 'easeInBack'
            // })
        }
    }
})();
EMA.CUSTOMSELECT = (function () {
    return {
        init: function(elem) {
            var self = this;
            $( document ).ready(function() {
                crear_select();
            })
                
            
              
              function isMobileDevice() {
                  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
              };


              $(elem).find(".selecionado_opcion, .icon_select_mate").on("click", function(){
                open_select(this);
              });
               
              var li = new Array();
              function crear_select(){
                //   console.log( elem.querySelectorAll("[data-mate-select='active']"));
              var div_cont_select = elem.querySelectorAll("[data-mate-select='active']");
              var select_ = '';
              for (var e = 0; e < div_cont_select.length; e++) {
              div_cont_select[e].setAttribute('data-indx-select',e);
              div_cont_select[e].setAttribute('data-selec-open','false');
              var ul_cont = document.querySelectorAll("[data-indx-select='"+e+"'] > .cont_list_select_mate > ul");
               select_ = document.querySelectorAll("[data-indx-select='"+e+"'] >select")[0];
               if (isMobileDevice()) { 
              select_.addEventListener('change', function () {
               _select_option(select_.selectedIndex,e);
              });
               }
              var select_optiones = select_.options;
              document.querySelectorAll("[data-indx-select='"+e+"']  > .selecionado_opcion ")[0].setAttribute('data-n-select',e);
              document.querySelectorAll("[data-indx-select='"+e+"']  > .icon_select_mate ")[0].setAttribute('data-n-select',e);
              for (var i = 0; i < select_optiones.length; i++) {
              li[i] = document.createElement('li');
              if (select_optiones[i].selected == true || select_.value == select_optiones[i].innerHTML ) {
              li[i].className = 'active';
              document.querySelector("[data-indx-select='"+e+"']  > .selecionado_opcion ").innerHTML = select_optiones[i].innerHTML;
              };
              li[i].setAttribute('data-index',i);
              li[i].setAttribute('data-selec-index',e);
              // funcion click al selecionar 
              li[i].addEventListener( 'click', function(){  _select_option(this.getAttribute('data-index'),this.getAttribute('data-selec-index')); });
              
              li[i].innerHTML = select_optiones[i].innerHTML;
              ul_cont[0].appendChild(li[i]);
              
                  }; // Fin For select_optiones
                }; // fin for divs_cont_select
              } // Fin Function 
              
              
              
              var cont_slc = 0;
              function open_select(idx){
                var idx1 =  idx.getAttribute('data-n-select');
                var ul_cont_li = document.querySelectorAll("[data-indx-select='"+idx1+"'] .cont_select_int > li");
                var hg = 0;
                var slect_open = document.querySelectorAll("[data-indx-select='"+idx1+"']")[0].getAttribute('data-selec-open');
              var slect_element_open = document.querySelectorAll("[data-indx-select='"+idx1+"'] select")[0];
               if (isMobileDevice()) { 
                if (window.document.createEvent) { // All
                var evt = window.document.createEvent("MouseEvents");
                evt.initMouseEvent("mousedown", false, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                  slect_element_open.dispatchEvent(evt);
              } else if (slect_element_open.fireEvent) { // IE
                slect_element_open.fireEvent("onmousedown");
              }else {
                slect_element_open.click();
              }
              }else {
              
                
                for (var i = 0; i < ul_cont_li.length; i++) {
              hg += ul_cont_li[i].offsetHeight;
              }; 
               if (slect_open == 'false') {  
               document.querySelectorAll("[data-indx-select='"+idx1+"']")[0].setAttribute('data-selec-open','true');
               document.querySelectorAll("[data-indx-select='"+idx1+"'] > .cont_list_select_mate > ul")[0].style.height = hg+"px";
               document.querySelectorAll("[data-indx-select='"+idx1+"'] > .icon_select_mate")[0].style.transform = 'rotate(180deg)';
              }else{
               document.querySelectorAll("[data-indx-select='"+idx1+"']")[0].setAttribute('data-selec-open','false');
               document.querySelectorAll("[data-indx-select='"+idx1+"'] > .icon_select_mate")[0].style.transform = 'rotate(0deg)';
               document.querySelectorAll("[data-indx-select='"+idx1+"'] > .cont_list_select_mate > ul")[0].style.height = "0px";
               }
              }
              
              } // fin function open_select
              
              function salir_select(indx){
              var select_ = document.querySelectorAll("[data-indx-select='"+indx+"'] > select")[0];
               document.querySelectorAll("[data-indx-select='"+indx+"'] > .cont_list_select_mate > ul")[0].style.height = "0px";
              document.querySelector("[data-indx-select='"+indx+"'] > .icon_select_mate").style.transform = 'rotate(0deg)';
               document.querySelectorAll("[data-indx-select='"+indx+"']")[0].setAttribute('data-selec-open','false');
              }
              
              
              function _select_option(indx,selc){
               if (isMobileDevice()) { 
              selc = selc -1;
              }
                  var select_ = document.querySelectorAll("[data-indx-select='"+selc+"'] > select")[0];
              
                var li_s = document.querySelectorAll("[data-indx-select='"+selc+"'] .cont_select_int > li");
                var p_act = document.querySelectorAll("[data-indx-select='"+selc+"'] > .selecionado_opcion")[0].innerHTML = li_s[indx].innerHTML;
              var select_optiones = document.querySelectorAll("[data-indx-select='"+selc+"'] > select > option");
              for (var i = 0; i < li_s.length; i++) {
              if (li_s[i].className == 'active') {
              li_s[i].className = '';
              };
              li_s[indx].className = 'active';
              
              };
              select_optiones[indx].selected = true;
                select_.selectedIndex = indx;
                select_.onchange();
                salir_select(selc); 
              }
              
              
              
        }
    }
})()
EMA.CONTACTFORM = (function() {
    var dinerocuentaContactForm;
    return {
        init: function(elem) {
            var self = this;
            dinerocuentaContactForm = $('#dinerocuentaContactForm');

            // console.log(dinerocuentaContactForm.find(".btn-action.submit"));
            dinerocuentaContactForm.validate({
              submitHandler: function(form) {
                console.log("submitHandler");
                var data = $(form).serialize();

                $.ajax({
                    type: 'post',
                    url: '/frontend/engine/engine.php',
                    data: data,
                    success: function (response) {
                        if(response.kind =="success") {
                             $('.status-message').removeClass("error");
                             $('.status-message').addClass("success");
                        } else {
                            $('.status-message').addClass("error");
                            $('.status-message').removeClass("success");
                        }
                        $('.status-message').hide().html(response.label);
                        $('.status-message').show();
                    }
                });
              },rules: {
                  "disclaimerCheck": {
                      required: true,
                      minlength: 1
                  }
                }
            });
            dinerocuentaContactForm.find(".btn-action").validate();
        }
    }
})();

$(function() {
    // Call when document is ready
    $(document).foundation();
    EMA.BUILD.init();
});
